<template>
    <el-upload
        ref="ossElUpload"
        v-bind="$attrs"
        class="upload-demo"
        action="upload"
        :http-request="submitUpload"
        :show-file-list="false"
        :disabled="loading || $attrs.disabled">
        <slot></slot>
        <i class="el-icon-upload" v-if="type === 'drag'"></i>
        <el-button type="primary" :loading="loading" v-if="needBtn && type === 'btn'" :disabled="$attrs.disabled">{{text}}</el-button>
        <slot name="tip"></slot>
    </el-upload>
</template>

<script>
import { ossUpload } from './api';
import { imageMinimize } from './imageMinimize.js';
const BYTES_UNIT = 1024;
const BYTES_PER_CHUNK = 100 * BYTES_UNIT * BYTES_UNIT; // 文件大小100MB
export default {
    name: 'lotsUploadButton',
    props: {
        bucket: {
            type: String,
            default: ''
        },
        businessKey: {
            type: String,
            default: ''
        },
        text: String,
        needBtn: {
            type: Boolean,
            default: true
        },
        publicRead: {
            type: [Number, String],
            default: 0
        },
        type: {
            type: String,
            default: 'btn'
        },
        compressImage: { // 5MB以上图片上传，是否进行图片压缩后再上传
            type: Boolean,
            default: false
        },
        compressQuality: { // 图片压缩的质量
            type: Number,
            default: 0.5
        },
        contentType: { // 上传内容类型
            type: String,
            default: ''
        }
    },
    // 定义抛出的事件名称
    emits: ['beforeUpload', 'callback'],
    data() {
        return {
            loading: false,
            uploadResArr: [], // 响应的集合
            isupload: true, // 是否进行文件上传
            uploadDataArr: [], // 上传文件集合
            errorNum: 0 // 记录上传失败次数
        };
    },
    methods: {
        submit() {
            this.$refs.ossElUpload.submit();
        },
        async imageFileProcess(file) { // 判断是否为图片 & 判断是否需要图片压缩
            const extensionTest = file.name.substring(file.name.lastIndexOf('.') + 1);
            if (extensionTest !== 'jpeg' && extensionTest !== 'jpg' && extensionTest !== 'png' && extensionTest !== 'gif') {
                return file;
            }
            const minimize = this.compressImage ? await imageMinimize(file, this.compressQuality) : file;
            return minimize;
        },
        /**
         * @description: 供外部使用，通过beforeUpload判断文件时候符合要求，调用此方法来终止上传
         * @method: 修改上传状态
         * @param {boolean} status 是否继续终止文件上传
         */
        handleUploadStatus(status) {
            this.isupload = status;
        },
        sendFiles() {
            this.uploadDataArr.forEach(item => {
                this.$emit('beforeUpload', item);
                if (item.file.size > BYTES_PER_CHUNK) {
                    this.$message.error('文件大小不能超过 100MB');
                } else if (this.amazonEscape(item.file.name)) {
                    this.$message.error(`文件名不能包含下列任何字符：? / \ * | " < >`);
                } else if (this.isupload) {
                    this.simpleUpload(item); // 简单数据上传
                }
            });
        },
        submitUpload(uploadData) {
            this.uploadDataArr.push(uploadData);
            clearTimeout(this.isReady);
            this.isReady = setTimeout(() => {
                this.sendFiles();
            }, 100);
        },
        async simpleUpload(uploadData) {
            const theFile = await this.imageFileProcess(uploadData.file);
            const file = new File([theFile], uploadData.file.name, {
                type: uploadData.file.type
            });
            this.loading = true;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('bucket', this.bucket);
            formData.append('businessKey', this.businessKey);
            formData.append('publicRead', this.publicRead);
            formData.append('contentType', this.contentType);
            ossUpload(formData).then((res) => {
                const list = {
                    ...res
                };
                list.data.name = uploadData.file.name;
                this.uploadResArr.push(list);
                if (typeof this.$attrs.multiple !== 'undefined' && this.$attrs.multiple !== false) { // 多文件上传
                    if (this.uploadResArr.length === this.uploadDataArr.length) {
                        this.$emit('callback', this.uploadResArr);
                        this.uploadResArr = [];
                        this.uploadDataArr = [];
                        this.loading = false;
                    }
                } else { // 单文件上传
                    this.$emit('callback', res);
                }
            }).catch(() => {
                this.errorNum++;
                this.$message.error(`${uploadData.file.name}上传失败`);
            }).finally(() => {
                if (typeof this.$attrs.multiple !== 'undefined' && this.$attrs.multiple !== false) {
                    // 判断有失败文件时，失败次数加上成功次数会等于上传次数则返回成功数组
                    if (this.errorNum > 0 && (this.uploadResArr.length + this.errorNum) === this.uploadDataArr.length) {
                        this.$emit('callback', this.uploadResArr);
                        this.uploadResArr = [];
                        this.uploadDataArr = [];
                        this.errorNum = 0;
                        this.loading = false;
                    }
                } else {
                    this.uploadDataArr = [];
                    this.loading = false;
                }
            });
        },
        // 除了下面这些字符外，其它都可以上传，参考oss接口上传文档
        _amazonShouldEscape(c) {
            const specialList = ['?', '/', '\\', '*', '|', '"', '<', '>'];
            return specialList.includes(c);
        },
        // 对文件名称特殊字符进行转换，否则会报接口异常
        amazonEscape(name) {
            let hexCount = 0;
            for (let i = 0; i < name.length; i++) {
                const c = name.charAt(i);
                if (this._amazonShouldEscape(c)) {
                    hexCount = hexCount + 1;
                }
            }
            if (hexCount === 0) {
                return false;
            } else {
                let ret = '';
                for (let i = 0; i < name.length; i++) {
                    const char = name.charAt(i);
                    if (this._amazonShouldEscape(char)) {
                        ret += char;
                    }
                }
                ret = [].filter.call(ret, (s, i, o) => o.indexOf(s) === i).join('');
                return ret;
            }
        }
    }
};
</script>
<style lang="less">
    .upload-demo {
        .el-upload {
            text-align: left;
        }
    }
</style>
