<template>
    <Paper class='user-center'>
        <div class="wrapper-user-info">
            <div class="uc-card" style="flex: 1;">
                <div class='information'>
                    <div class="card-header">
                        <span class="title">基本信息</span>
                        <span class="subtitle">同步时间：{{userInfo.updateTime}}</span>
                    </div>
                    <div class='card-body'>
                        <div class='head-portrait'>
                            <UploadBtn :needBtn="false" :bucket="config.commonBucket" @callback="(res) => {
                                    handleUploadCb(res, userInfo)
                                }">
                                <img v-if="userInfo.picUrl" :src="userInfo.picUrl" class="head-spare" alt="">
                                <div v-else class="head-spare"></div>
                            </UploadBtn>
                            <h4 class="usr-name">{{userInfo.userName}}</h4>
                        </div>
                        <el-row class="basic-info">
                            <el-col class='text-line' :span='12'>账号： {{userInfo.userCode}}</el-col>
                            <!-- <el-col class='text-line' :span='12'>状态： {{userInfo.name}}</el-col> -->
                            <el-col class='text-line' :span='12'>手机： {{userInfo.mobile}}</el-col>
                            <el-col class='text-line' :span='12'>员工编码： {{userInfo.employeeId}}</el-col>
                            <el-col class='text-line' :span='24'>邮箱地址： {{userInfo.email}}</el-col>
                            <el-col class='text-line' :span='24'>部门职位： {{userInfo.orgName}} {{userInfo.positionName ? userInfo.positionName : ''}}</el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="uc-card" style="flex: 0 0 480px;margin-left: 10px;">
                <div class="account-info">
                    <div class="card-header">
                        <span class="title">账号信息</span>
                    </div>
                    <div class='card-body'>
                        <ul style="padding: 0;">
                            <li v-for="account in userAccount" :key="account.id" class="acc-li">
                                <img v-if="account.picUrl" :src="account.picUrl" class="head-spare" alt="">
                                <div v-else class="head-spare"></div>
                                <span class="acc-wrapper">
                                    <el-row>
                                        <el-col :span="12" v-if="account.accountType === 1 || account.accountType === 2">{{account.accountName}}</el-col>
                                        <el-col :span="12" v-else>{{account.account}}</el-col>
                                        <el-col :span="12" class="accountType">{{accountTypeMap[account.accountType]}}</el-col>
                                    </el-row>
                                </span>
                            </li>
                        </ul>
                        <ul class="card-body-btn">
                            <li class="auth-btn">
                                <a :href="btnUrlOrigin ? `${btnUrlOrigin}/#/apply` : 'javascript:;'" target="_blank">权限申请</a>
                            </li>
                            <li class="auth-btn">
                                <a :href="btnUrlOrigin ? `${btnUrlOrigin}/flows/#/agent` : 'javascript:;'" target="_blank">代他人权限申请</a>
                            </li>
                            <li class="auth-btn">
                                <a :href="btnUrlOrigin ? `${btnUrlOrigin}/flows/#/copy` : 'javascript:;'" target="_blank">复制他人权限</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <el-col :span='24' class="uc-card" style="margin-top: 10px;">
                <el-tabs value="role" class="scrll-wrapp">
                    <el-tab-pane label="角色权限" name="role">
                        <el-table stripe :data='roles'>
                            <el-table-column prop='roleName' label='角色名称'></el-table-column>
                            <el-table-column prop='applicationName' label='应用'></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="应用权限" name="resource">
                        <el-tree :data="resouces" :props="defaultResouceProps"></el-tree>
                    </el-tab-pane>
                    <el-tab-pane label="租户权限" name="tenant">
                        <el-table stripe :data='tenants'>
                            <el-table-column prop='tenantName' label='租户列表'></el-table-column>
                            <el-table-column prop='tenantCode' label='编码名称'></el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="数据权限预览" name="dataAuthPreview">
                        <dataAuth-detail ref="dataAuth" :userData="userInfo"></dataAuth-detail>
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </div>
    </Paper>
</template>
<script>
import Paper from '@/components/core/Paper';
import UserCenterApi from './UserCenterApi.js';
import UploadBtn from '@/components/lots/oss/UploadBtn.vue';
import DataAuthDetail from './DataAuthDetail';
import config from '@/config/user.env.js';
import { findCurrUser } from '@/api/userApi';
import { mapMutations } from 'vuex';
const BTN_AUTH_URL = {
    'SIT': 'https://permsit.midea.com',
    'UAT': 'https://permuat.midea.com',
    'VER': '',
    'PROD': 'https://perm.midea.com'
};
export default {
    name: 'userCenter',
    data() {
        return {
            config,
            userInfo: {},
            userAccount: [],
            accountTypeMap: {
                0: '集团用户',
                1: '微信',
                2: '手机',
                3: '第三方授权appkey用户',
                99: '游客'
            },
            roles: [],
            resouces: [],
            tenants: [],
            defaultResouceProps: {
                children: 'subResources',
                label: 'resourceName'
            }
        };
    },
    computed: {
        btnUrlOrigin() {
            return encodeURI(BTN_AUTH_URL[config.env]);
        }
    },
    methods: {
        ...mapMutations({
            updateUserInfo: 'updateUserInfo'
        }),
        // 图片上传后的回调
        handleUploadCb(res, data) {
            UserCenterApi.userAccount(data.userAccountId, {
                picUrl: res.data.downUrl,
                id: data.userAccountId,
                version: data.userAccountVersion
            }).then(res => {
                if (res && +res.code === 0) {
                    this._getUserInfo();
                    this._getAccount();
                    // 上传成功后重新请求用户信息，以便更新右上角头像
                    findCurrUser().then(res => {
                        if (res && +res.code === 0) {
                            this.updateUserInfo(res.data);
                        }
                    });
                }
            });
        },
        _getUserInfo() {
            UserCenterApi.getUserInfo().then(res => {
                if (res && res.data) {
                    this.userInfo = res.data;
                    UserCenterApi.getUserRoles(this.userInfo.userCode).then(res => {
                        if (res && res.data) {
                            this.roles = res.data || [];
                        }
                    });
                    UserCenterApi.getUserInfoResources({ userCode: this.userInfo.userCode }).then(res => {
                        if (res && res.data) {
                            this.resouces = [{
                                resourceName: '应用',
                                subResources: res.data || []
                            }];
                        }
                    });
                    UserCenterApi.getUserTenants(this.userInfo.userCode).then(res => {
                        if (res && res.data) {
                            this.tenants = res.data || [];
                        }
                    });
                };
            });
        },
        _getAccount() {
            UserCenterApi.getAccount().then(res => {
                if (res && res.data) {
                    this.userAccount = res.data.list || [];
                };
            });
        }
    },
    mounted() {
        this._getUserInfo();
        this._getAccount();
        this.$refs.dataAuth.getSystems();
    },
    components: {
        Paper,
        UploadBtn,
        'dataAuth-detail': DataAuthDetail
    }
};
</script>
<style lang='less'>
.user-center {
    background: #f3f4f6;
    padding: 0;
    margin: 0;
    .wrapper-user-info{
        display: flex;
        flex-direction: row;
    }
    .uc-card {
        background: #fff;
        .card-header {
            padding: 15px;
            font-size: 14px;
            border-bottom: 2px solid #f6f8fa;
            .subtitle {
                float: right;
                font-size: 12px;
                color: #aaa;
            }
        }
        .card-body {
            display: flex;
            padding: 15px;
            .head-portrait {
                text-align: center;
                margin: 15px 50px 0 30px;
                .usr-name{
                    color: #333;
                    font-weight: normal;
                }
            }
            .head-spare {
                height: 72px;
                width: 72px;
                border-radius: 50%;
                flex-shrink: 0;
                background: #e4e4e4;
            }
        }

        .el-tab-pane{
            padding: 0 15px 15px 15px;
        }
        .el-tabs__nav{
            margin-left: 25px;
            height: 46px;
            padding-top: 6px;
        }
        .scrll-wrapp{
            .el-tabs__content{
                height: calc(100vh - 370px);
                overflow: auto;
            }
        }
    }
    .account-info {
        .card-body {
            flex-direction: column;
            height: 151.53px;
            overflow: auto;
            overflow: overlay;
            .acc-li {
                display: flex;
                flex-direction: row;
                padding-bottom: 10px;
                border-bottom: 1px solid #f1f1f1;
                &:not(:last-child){
                    margin-bottom: 15px;
                }
                .head-spare {
                    width: 30px;
                    height: 30px;
                    float: left;
                }
                .acc-wrapper {
                    flex: 1;
                    line-height: 30px;
                    padding-left: 15px;
                    .accountType{
                        color: #aaa;
                    }
                }
            }
        }
    }
    .basic-info {
        width: 100%;
        font-size: 12px;
        color: #333;
        .text-line {
            margin: 10px 0;
        }
    }
    .el-input.is-disabled .el-input__inner {
        background-color: #ffffff;
        border-color: #e4e7ed;
        color: #101010;
        cursor: auto;
    }
    .el-form-item__label {
        color: #999999;
        font-weight: normal;
    }
    .el-tabs__item {
        font-weight: normal;
    }
    .card-body-btn {
        padding: 0;
        margin-top: 0;
        .auth-btn {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #f1f1f1;
            a {
                text-decoration: none;
                font-size: 12px;
                padding: 4px 0;
                color: #4285f5;
                &:hover {
                    color: #66b1ff;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
