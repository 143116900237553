const DEFALUT_QUALITY = 0.5; // 默认的图片压缩质量
const ONE_KB = 1024; // 1KB大小
const ONE_MB = 5;
const DEFALUT_LIMITSIZE = ONE_MB * ONE_KB; // 默认的压缩临界大小，500KB

/**
 * @description: 压缩图片
 * @param {object} file 文件对象
 * @param {number} quality 压缩质量比例，取值0至1（与压缩质量有关，数值越小，质量越低）
 * @param {number} limitSize 高于该值将进行压缩，否则不压缩，单位：KB
 * @return {promise} 返回promise对象
 */
export function imageMinimize(file, quality = DEFALUT_QUALITY, limitSize = DEFALUT_LIMITSIZE) {
    return new Promise(resolve => {
        if (file.size <= limitSize * ONE_KB) { // 判断图片是否大于预设的大小，是就直接上传
            resolve(file);
        } else { // 反之压缩图片
            const reader = new FileReader();
            // 将图片转成 base64 格式
            reader.readAsDataURL(file);
            // console.log(reader);
            // 读取成功后的回调
            reader.onloadend = function () {
                const result = this.result;
                const img = new Image();
                img.src = result;
                img.onload = function () {
                    // BASE64转图片
                    const arr = compress(img, quality).split(',');
                    const bstr = atob(arr[1]);
                    let n = bstr.length;
                    const u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    resolve(u8arr);
                };
            };
        }
    });
};

/**
 * @description: 压缩图片对象
 * @param {object} img 需要压缩的图片对象
 * @param {number} quality 压缩质量比例，取值0至1（与压缩质量有关，数值越小，质量越低）
 * @return {string} 返回压缩后的base64字符串
 */
function compress(img, quality = 1) {
    const PIXEL_100 = 1000000;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const tCanvas = document.createElement('canvas'); // 瓦片canvas
    const tctx = tCanvas.getContext('2d');
    // const initSize = img.src.length;
    let width = img.width;
    let height = img.height;
    let ratio = 1;
    ratio = Math.sqrt(ratio); // 计算图片压缩比
    width /= ratio;
    height /= ratio;
    canvas.width = width;
    canvas.height = height;
    ctx.fillStyle = '#fff'; // 铺底色
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    let count;
    if ((count = width * height / PIXEL_100) > 1) { // 如果图片像素大于100万则使用瓦片绘制
        // console.log('超过100万像素');
        // 计算要分成多少块瓦片
        count = ~~(Math.sqrt(count) + 1);
        // 计算每块瓦片的宽和高
        const nw = ~~(width / count);
        const nh = ~~(height / count);
        tCanvas.width = nw;
        tCanvas.height = nh;
        for (let i = 0; i < count; i++) {
            for (let j = 0; j < count; j++) {
                tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh);
                ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
            }
        }
    } else {
        ctx.drawImage(img, 0, 0, width, height);
    }
    // 进行最小压缩
    const ndata = canvas.toDataURL('image/jpeg', quality);
    /* console.log('压缩前：' + initSize);
    console.log('压缩后：' + ndata.length);
    console.log('ndata:' + ndata);
    console.log('压缩率：' + ~~(100 * (initSize - ndata.length) / initSize) + '%'); */
    tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
    return ndata;
};
