<template>
    <div class="data-auth-preview-container">
        <el-row class="data-auth-preview-container-row">
            <el-col :span="8" class="data-auth-preview-container-row-col">
                <el-input
                    class="select-input"
                    placeholder="搜索数据项"
                    size="mini"
                    prefix-icon="el-icon-search"
                    v-model="selectLeftValue"
                    @keyup.enter.native="getSystems">
                </el-input>
                <div class="system-list" v-loading="leftLoading">
                    <el-menu :class="{'system-menu': true, 'system-menu-page': !isDialog}"
                        :default-active="defaultActive">
                        <el-menu-item class="system-menu-item" v-for="(item, index) in systems"
                            :index="index + ''" :key="item.id"
                            @click="() => handleLeftClick(index)"
                            >{{item.dataName}}</el-menu-item>
                    </el-menu>
                </div>
            </el-col>
            <el-col :span="16" class="role-selector-row-col">
                <div class="auth-selector">
                    <div v-show="isTree" class="wrapp-tree">
                        <div v-if="treeData.length > 0">
                            <el-tree :data="treeData"
                                class="auth-tree"
                                ref="tree"
                                show-checkbox
                                node-key="col"
                                :props="defaultProps"
                                :filter-node-method="filterNode">
                            </el-tree>
                        </div>
                        <div class="no-data" v-else>
                            <span>暂无数据</span>
                        </div>
                    </div>
                    <div v-show="!isTree" :class="{'auth-list': true, 'auth-list-page': !isDialog}" v-loading="rightLoading">
                        <div v-if="roles.length > 0">
                            <el-checkbox class="auth-checkbox" v-for="item in roles"
                                :key="item.authCode"
                                v-model="item.selectedFlag"
                                :label="item.authCode">
                                {{item.authName}}
                            </el-checkbox>
                        </div>
                        <div class="no-data" v-else>
                            <span>暂无数据</span>
                        </div>
                    </div>
                    <div class="auth-footer">
                        <el-pagination
                            class="pull-right"
                            v-show="!isTree && total > 0"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="currentPage"
                            layout='total, sizes, prev, pager, next, jumper'
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import UserCenterApi from './UserCenterApi.js';
export default {
    name: 'DataAuth',
    props: ['userData'],
    data () {
        return {
            selectLeftValue: '',
            systems: [],
            leftLoading: false,
            isDialog: false,
            defaultActive: '0',
            isTree: false,
            rightLoading: false,
            roles: [],
            treeData: [],
            pageSize: 10,
            currentPage: 1,
            total: 0,
            hasChecked: false,
            defaultProps: {
                children: 'subs',
                label: (data) => {
                    return `${data.authName}${data.remark ? `(${data.remark})` : ''}`;
                }
            }
        };
    },
    methods: {
        getSystems () {
            UserCenterApi.getDataTypeList({
                dataName: this.selectLeftValue ? this.selectLeftValue : null,
                enableFlag: 1
            }).then(res => {
                if (res && res.data && res.data.list) {
                    this.systems = res.data.list;
                }
                this.leftLoading = false;
                this.currentSystems = 0;
                if (this.systems.length) {
                    this.getRoles();
                }
            });
        },
        getRoles () {
            this.selectedAppDataCode = this.systems[this.currentSystems].dataCode;
            const params = {
                dataCode: this.selectedAppDataCode,
                pageSize: this.pageSize,
                pageNo: this.currentPage,
                userCode: this.userData.userCode,
                search: '',
                selectedFlag: this.hasChecked ? 1 : null
            };
            UserCenterApi.getDataAuth(params).then(res => {
                if (res && res.data && +res.code === 0) {
                    this.handleRender(res);
                } else {
                    this.handleInitWhileError();
                }
                this.rightLoading = false;
            });
        },
        handleRender (res) {
            if (res.data.userDataAuthDtos.pageNo) {
                this._renderTypeList(res);
            } else {
                this._renderTypeTree(res);
            }
        },
        _renderTypeList (res) {
            this.isTree = false;
            this.roles = res.data.userDataAuthDtos.list.map(item => {
                item.selectedFlag = !!item.selectedFlag;
                return item;
            });
            this.total = res.data.userDataAuthDtos.totalCount;
        },
        _renderTypeTree (res) {
            this.isTree = true;
            const { userDataAuthDtos, userDataAuthDtoSelected } = res.data;
            this.treeData = userDataAuthDtos;
            this.$nextTick(() => {
                if (this.$refs.tree) this.$refs.tree.setCheckedKeys(userDataAuthDtoSelected);
            });
        },
        handleLeftClick (index) {
            this.currentPage = 1;
            this.pageSize = 10;
            this.currentSystems = index;
            this.defaultActive = index.toString();
            this.rightLoading = true;
            this.getRoles();
        },
        // eslint-disable-next-line
        filterNode (value, data) {
            if (!value.text && !value.flag) return true;
            if (value.text && (!value.flag)) {
                if (data.isShow) {
                    if (data.subs && data.subs.length) {
                        data.subs.forEach(item => {
                            item['isShow'] = true;
                        });
                    }
                    delete data.isShow;
                    return true;
                }
                if (data.subs && data.subs.length && data.authName.indexOf(value.text) !== -1) {
                    data.subs.forEach(item => {
                        item['isShow'] = true;
                    });
                }
                return data.authName.indexOf(value.text) !== -1;
            }
            if (value.text || value.flag) {
                if (data.isShow && data.selectedFlag === +value.flag) {
                    if (data.subs && data.subs.length) {
                        data.subs.forEach(item => {
                            item['isShow'] = true;
                        });
                    }
                    delete data.isShow;
                    return true;
                }
                if (data.subs && data.subs.length && data.authName.indexOf(value.text) !== -1 && data.selectedFlag === +value.flag) {
                    data.subs.forEach(item => {
                        item['isShow'] = true;
                    });
                }
                return data.authName.indexOf(value.text) !== -1 && data.selectedFlag === +value.flag;
            }
        },
        handleSizeChange (val) {
            this.pageSize = val;
            this.getRoles();
        },
        handleCurrentChange (val) {
            this.currentPage = val;
            this.getRoles();
        },
        handleInitWhileError () {
            this.isTree = false;
            this.roles = [];
            this.total = 0;
            this.treeData = [];
        }
    }
};
</script>

<style lang="less" scoped>
.data-auth-preview-container {
    padding: 0 16px 16px 2px;
    &-row{
        height: 100%;
        &-col{
            display: flex;
            flex-direction: column;
            height: 100%;
            .select-input{
                box-sizing: border-box;
                padding-right: 15px;
                margin-bottom: 10px;
            }
        }
    }
    .system-list {
        flex: 1;
        height: 460px;
        overflow: scroll;
        .system-menu {
            padding-right: 15px;
            border-right: 0;
            overflow-y: auto;
        }
        .system-menu-item {
            width: auto;
            height: 40px;
            line-height: 40px;
            &:hover {
                background-color: #f5f7fa;
            }
            &.is-active {
                background-color: #f0f7ff;
            }
        }
    }

    .auth-selector {
        padding: 0 0 0 15px;
        // border-left: 1px solid #e6e6e6;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: left;
        .no-data {
            margin-top: 120px;
            text-align: center;
            color: #bfbfbf;
        }
    }
    .auth-search{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        &-input{
            width: 360px;
            margin-right: 10px;
        }
    }
    .wrapp-tree{
        flex: 1;
        overflow: scroll;
    }
    .auth-tree{
        .el-tree-node__content, .el-tree-node{
            padding: 1px 0;
        }
    }
    .auth-list {
        flex: 1;
        overflow: auto;
        margin: 10px 0 0 0;
        &.auth-list-page {
            height: 400px;
        }
        .auth-checkbox {
            width: 45%;
            box-sizing: border-box;
            margin: 0 10px 10px 0;
            padding: 0 10px;
            line-height: 2em;
        }
    }
    .auth-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        .check-all {
            padding: 0 10px;
            line-height: 40px;
        }
        .pull-right {
            line-height: 40px;
            .el-button {
                margin-left: 15px;
            }
        }
    }
}
</style>
