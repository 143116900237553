import request from '@/utils/http';

const userCenter = {
    // 获取数据权限应用列表
    getDataTypeList(params) {
        return request({
            url: '/api-auth/dataAuths',
            method: 'get',
            params: params
        });
    },
    getDataAuth ({ userCode, dataCode, pageSize, pageNo, queryParam, search, selectedFlag }) {
        return request({
            url: `/api-auth/user/${userCode}/dataAuth/${dataCode}`,
            method: 'get',
            params: {
                pageSize,
                pageNo,
                queryParam,
                search,
                selectedFlag
            }
        });
    },
    // 获取个人权限信息
    getUserInfoResources(params) {
        return request({
            url: `/api-auth/getUserInfoResources`,
            method: 'get',
            params: params
        });
    },
    getAccount() {
        return request({
            url: '/api-auth/userAccountCurrent',
            method: 'get'
        });
    },
    getUserInfo() {
        return request({
            url: '/api-auth/userInfoCurrent',
            method: 'get'
        });
    },
    getUserRoles() {
        return request({
            url: '/api-auth/userInfoCurrent/roles',
            method: 'get'
        });
    },
    getUserResources() {
        return request({
            url: '/api-auth/getAllResources',
            method: 'get'
        });
    },
    getUserTenants() {
        return request({
            url: '/api-auth/userInfoCurrent/tenants',
            method: 'get'
        });
    },
    userAccount(id, data) {
        return request({
            url: `/api-auth/userAccount/${id}`,
            method: 'put',
            data
        });
    }
};
export default userCenter;
